import React from "react";
import logo from '../logos/logo_img.png'; // Ensure you have the correct path
import './FooterNew.css';

import { BsTwitterX } from "react-icons/bs";
import {FaFacebook} from 'react-icons/fa'
import {FaYoutube} from 'react-icons/fa'
const FooterNew = () => {
    return(
        <div id="footer-container">
            <div id="footer-links-logo">

                <div id="footer-links">
                    <div className="footer-col">
                        <h3>
                            About Us
                        </h3>
                        <ul>
                            <li>
                                <a href="/about">
                                Who we are?
                                </a>
                                
                            </li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h3>
                            Get Involved
                        </h3>
                        <ul>
                            <li>
                            <a href="/get_involved">
                                    Participate
                            </a>
                            </li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h3>
                            
                            Contact Us
                                                    </h3>
                        <ul>
                            <li>
                            <a href="/contact_us">
                                Get Help
                            </a>
                            </li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h3>
                            Social Media
                        </h3>
                        <a style={{paddingLeft: '15px'}} href="https://www.facebook.com/ccnorder/" target="_blank" rel="noopener noreferrer">
                    <FaFacebook size={25} color="white" />
                        </a>
                        {/* <br></br> */}

                <a  style={{paddingLeft: '15px'}} href="https://x.com/ccnorder" target="_blank" rel="noopener noreferrer">
                    <BsTwitterX size={25} color="white" />
                  </a>
                  {/* <br></br> */}

                  <a  style={{paddingLeft: '15px'}} href="https://x.com/ccnorder" target="_blank" rel="noopener noreferrer">
                    <FaYoutube size={25} color="white" />
                </a>
                    </div>
                </div>

                <div id="footer-logo">
                    <a href="/">
                        <img src={logo} alt="Logo" />
                    </a>
                </div>
            </div>

            <div id="copyright">
                &copy; Constitutional Order Institute inc.
            </div>
        </div>
    );
};

export default FooterNew;